import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "antd";
import {Col as BootstrapCol, Row as BootstrapRow} from "react-bootstrap";
import buero from "../../assets/buero.jpg";
import {IJobPageProps, renderAboutUs, renderHeadBanner} from "./EntryPage";
import * as countly from "../../services/countlyService";

export const JobOverviewPage = ({job}: IJobPageProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const goFurther = (funnelButtonText: string) => {
        countly.benefitsOpened(location.pathname, funnelButtonText, 1);
        navigate(`${location.pathname}-2`);
    }

    const taskIconSet = ['🧑‍💻', '💻', '👔'];

    countly.jobOpened(location.pathname)

    return (
        <div>
            {renderHeadBanner()}
            <div>
                <h3 style={{fontSize: 22, lineHeight: '14px'}}>
                    Werde unser nächster
                </h3>
                <h2 style={{fontSize: 26, lineHeight: '32px', marginBottom: 40}}>
                    <strong>{job.jobTitle} (m/w/d)</strong>
                </h2>
            </div>
            <div className={'text-inner'}>
                <h5>📍 {job.workPlace}</h5>
                <p>🕐 {job.workTime}</p>
            </div>
            <div className={'text-box'}>
                <h3 style={{fontSize: 24}}>
                    Hast du Lust auf einen<span>&nbsp;</span>
                    <b>sicheren Job</b>,
                    <span>&nbsp;</span>mit vielen weiteren Vorteilen wie z.B.
                    <span>&nbsp;</span><b>mobiles Arbeiten</b><span>&nbsp;</span>
                    und <b>flexiblen Arbeitszeiten</b>?
                </h3>
            </div>
            <div className={'banner'}>
                <BootstrapRow className={'justify-content-center'}>
                    <BootstrapCol xs={12} md={5} lg={5} style={{margin: '2rem 0', marginRight: 20}}>
                        <Button
                            type={'primary'}
                            onClick={() => goFurther('Ja absolut')}
                            className={'extra-large-button'}
                        >
                            Ja absolut 👍
                        </Button>
                    </BootstrapCol>
                    <BootstrapCol xs={12} md={6} lg={5} style={{margin: '2rem 0'}}>
                        <Button
                            type={'primary'}
                            onClick={() => goFurther('Mehr erfahren')}
                            className={'extra-large-button'}
                        >
                            Mehr erfahren 💬
                        </Button>
                    </BootstrapCol>
                </BootstrapRow>
            </div>
            <div className={'banner-inline'}>
                <h2 style={{fontSize: 26, marginBottom: 50}}>
                    So sehen deine Aufgaben bei uns aus
                </h2>
                <div className={'text-line'}>
                    <BootstrapRow className={'justify-content-center'}>
                        {job.tasks.map((task, index) => {
                            return (
                                <BootstrapCol className={'text-col'} span={24} xxs={12} md={6} lg={3}
                                              key={`task-${index}`}>
                                    <p className={'text-icon'}>{taskIconSet[index]}</p>
                                    {task}.
                                </BootstrapCol>
                            );
                        })}
                    </BootstrapRow>
                </div>
            </div>
            <div className={'banner'}>
                <h2 className={'h2-top'}>
                    Klingt gut!
                </h2>
                <Button
                    type={'primary'}
                    onClick={() => goFurther('Was sind meine Vorteile')}
                    className={'extra-large-button'}
                >
                    Was sind meine Vorteile?
                </Button>
            </div>
            {renderAboutUs()}
            <div style={{paddingBottom: '40px'}}>
                <h2 className={'h2-top'}>
                    <strong>Super!</strong>
                </h2>
                <Button
                    type={'primary'}
                    onClick={() => goFurther('Was bekomme ich bei euch')}
                    className={'extra-large-button'}
                >
                    Was bekomme ich bei euch?
                </Button>
            </div>
            <div className={'banner'} style={{paddingBottom: '40px'}}>
                <h2 style={{fontSize: 26}}>
                    Unser Büro am Münchner Standort 🏢
                </h2>
                <img className={'responsive-image'} src={buero} alt="Dein Büro"/>
            </div>
        </div>
    );
}
