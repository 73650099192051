import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Button,
    Checkbox,
    Form,
    Input,
    message,
    Radio,
    RadioChangeEvent,
    Space, Spin,
    Upload,
    UploadProps
} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {Col as BootstrapCol, Container, Row as BootstrapRow} from "react-bootstrap";
import buero2 from "../../assets/buero2.jpg";
import {IJobPageProps} from "./EntryPage";
import {sendApplication} from "../../services/applicationService";
import {getUrl} from "../../services/apiService";
import {uploadRoute} from "../../constants/routes";
import * as countly from "../../services/countlyService";

const {Dragger} = Upload;

type FieldType = {
    fullname?: string;
    email?: string;
    phone?: string;
    experience: string;
    skills: string[];
};

export const ContactPage = ({job}: IJobPageProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [radioExpValue, setRadioExpValue] = React.useState(0);

    const [fileList, setFileList] = React.useState<string[]>([]);

    const jobUrl = location.pathname.slice(0, location.pathname.length - 2);

    const onFinish = async (values: FieldType) => {
        setLoading(true);
        countly.applicationSent(jobUrl);
        const body = {
            ...values,
            fileUpload: fileList,
            skills: values.skills || [],
            job: job.jobTitle,
        }
        await sendApplication(body).then(r => {
            if (r.status !== 201) throw new Error();
            navigate('/bewerbung-erhalten')
        }).catch(() => {
            message.error(`Das hat leider nicht funktioniert. Sende uns deine Bewerbung doch gerne per Mail an jobs@it-werklerei.de`);
        }).finally(() => setLoading(false));
    }

    const onFinishFailed = () => window.scrollTo(0, 0);

    const onRadioExpChange = (e: RadioChangeEvent) => setRadioExpValue(e.target.value);

    const onInputFocusLost = (e: any) => countly.formFocusLost(jobUrl, e.target.id, e.target.value);

    const normalizeFileUpload = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        action: getUrl(uploadRoute),
        beforeUpload: (file) => {
            const allowed = file.type === 'application/pdf' // .pdf
                || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
                || file.type === 'application/msword' // .doc
                || file.type === 'image/jpeg' // .jpg & .jpeg
                || file.type === 'image/png'; // .png
            if (!allowed) {
                message.error(`Das Dateiformat von '${file.name}' wird nicht unterstützt.`);
            }
            const sizeFits = file.size < (1000 * 1000 * 12000);
            if (!sizeFits) {
                message.error(`Die Datei '${file.name}' überschreitet die maximale Dateigröße.`);
            }
            return (allowed && sizeFits) || Upload.LIST_IGNORE;
        },
        onChange(info) {
            const {status} = info.file;
            if (status === 'done') {
                if (info.file.response && info.file.response.fileName) {
                    setFileList([info.file.response.fileName, ...fileList]);
                }
                message.success(`Die Datei '${info.file.name}' wurde erfolgreich hochgeladen.`);
            } else if (status === 'error') {
                message.error(`'${info.file.name}' konnte nicht hochgeladen werden.`);
            }
        },
    };

    return (
        <Spin spinning={loading}>
            <h1 style={{marginTop: '80px', marginBottom: '40px'}}>
                <strong>Wie können wir dich am besten erreichen? 📓</strong>
            </h1>
            <Container>
                <BootstrapRow span={12} className="justify-content-md-center">
                    <BootstrapCol lg={6}>
                        <div style={{marginBottom: '2rem'}}>
                            <img className={'responsive-image'} src={buero2} alt="Dein Büro"/>
                            <p><i>Dein zukünftiges Büro</i></p>
                        </div>
                    </BootstrapCol>
                    <BootstrapCol lg={6}>
                        <Form
                            name="bewerbung"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item<FieldType>
                                name="fullname"
                                hasFeedback
                                validateDebounce={500}
                                rules={[{
                                    required: true,
                                    pattern: new RegExp(/^[a-z ,.'-]{0,40}$/i),
                                    message: 'Bitte teile uns deinen vollständigen Namen mit'
                                }]}
                            >
                                <Input
                                    placeholder={'Dein vollständiger Name'}
                                    size={'large'}
                                    style={{fontSize: 16, padding: '12px 11px'}}
                                    onBlur={onInputFocusLost}
                                />
                            </Form.Item>

                            <BootstrapRow>
                                <BootstrapCol lg={6}>
                                    <Form.Item<FieldType>
                                        name="email"
                                        hasFeedback
                                        validateDebounce={500}
                                        rules={[{
                                            required: true,
                                            type: 'email',
                                            message: 'Bitte teile uns deine E-Mail Adresse mit'
                                        }]}
                                        style={{
                                            display: 'inline-block',
                                            //width: 'calc(50% - 8px)',
                                            width: '100%',
                                            //marginRight: '8px'
                                        }}
                                    >
                                        <Input
                                            placeholder="Deine E-Mail Adresse"
                                            size={'large'}
                                            style={{fontSize: 16, padding: '12px 11px'}}
                                            onBlur={onInputFocusLost}
                                        />
                                    </Form.Item>
                                </BootstrapCol>
                                <BootstrapCol lg={6}>
                                    <Form.Item<FieldType>
                                        name="phone"
                                        hasFeedback
                                        validateDebounce={500}
                                        rules={[{
                                            required: true,
                                            pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/),
                                            message: 'Bitte teile uns deine Telefonnummer mit'
                                        }]}
                                        style={{
                                            display: 'inline-block',
                                            //width: 'calc(50% - 8px)',
                                            width: '100%',
                                            //marginLeft: '8px'
                                        }}
                                    >
                                        <Input
                                            placeholder="Deine Telefonnummer"
                                            size={'large'}
                                            style={{fontSize: 16, padding: '12px 11px'}}
                                            onBlur={onInputFocusLost}
                                        />
                                    </Form.Item>
                                </BootstrapCol>
                            </BootstrapRow>

                            <h4 style={{textAlign: 'left'}}>
                                Deine bisherige Erfahrung als {job.jobTitle}
                            </h4>
                            <Form.Item<FieldType>
                                name="experience"
                                rules={[{
                                    required: true,
                                    message: 'Bitte teile uns deine Erfahrungen mit',
                                }]}
                                style={{minHeight: 120}}
                            >
                                <Radio.Group
                                    onChange={onRadioExpChange}
                                    style={{textAlign: 'left', position: 'absolute', left: 0}}
                                >
                                    <Space direction="vertical">
                                        <Radio value={'< 1 Jahr'}>Unter 1 Jahr</Radio>
                                        <Radio value={'1 - 3 Jahre'}>1 - 3 Jahre</Radio>
                                        <Radio value={'3 - 5 Jahre'}>3 - 5 Jahre</Radio>
                                        <Radio value={'> 5 Jahre'}>Über 5 Jahre</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <h4 style={{textAlign: 'left', visibility: radioExpValue === 0 ? 'hidden' : 'visible'}}>
                                Was trifft noch auf dich zu?
                            </h4>
                            <Form.Item<FieldType>
                                name={"skills"}
                                style={{textAlign: 'left'}}
                                hidden={radioExpValue === 0}
                            >
                                <Checkbox.Group>
                                    {job.niceToHaveSkills.map((skill, index) => {
                                        return (
                                            <Checkbox
                                                style={{marginTop: 5, marginBottom: 5, width: '100%'}}
                                                value={skill}
                                                key={`skill-${index}`}
                                            >
                                                {skill}
                                            </Checkbox>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                name="fileUpload"
                                valuePropName="fileList"
                                getValueFromEvent={normalizeFileUpload}
                                noStyle
                            >
                                <Dragger {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">Bewerbungsunterlagen hochladen</p>
                                    <p className="ant-upload-hint">
                                        Du kannst eine, mehrere oder keine Dateien anhängen.
                                        <br/>
                                        Unterstützte Dateiformate: *.pdf, *.docx, *.jpeg/png
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit' className={'submit-button'}>
                                    Deine Bewerbung absenden 📧
                                </Button>
                            </Form.Item>
                        </Form>
                    </BootstrapCol>
                </BootstrapRow>
            </Container>
            <p className={'text-box'}>
                🔒 Sichere Verbindung. *Kein Spam*
            </p>
        </Spin>
    );
}

export const ContactSuccessPage = () => {

    const goToHomepage = () => {
        countly.homepageOpened();
        window.location.href = 'https://it-werklerei.de'
    }

    return (
        <div>
            <h1 style={{marginTop: '80px'}}>
                <strong>Wir haben deine Bewerbung erhalten! 👌</strong>
            </h1>
            <h3 style={{marginTop: '40px'}}>
                Danke, das du dir die Zeit genommen hast. Wir werden uns bald bei dir melden.
            </h3>
            <h4 style={{marginTop: '120px'}}>
                <i>
                    <Button
                        type='primary'
                        className={'large-button'}
                        onClick={goToHomepage}
                    >
                        Zurück zur Webseite
                    </Button>
                </i>
            </h4>
        </div>
    );
}
